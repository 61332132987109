<template>
    <v-card max-width="374" class="mx-auto my-5" @click="$router.push({name: 'dashboard.tags.show', params: {id: tag.id}})">
        <v-img height="150" :src="tag.thumbnail_url"/>
        <v-card-title class="mt-1">
            {{tag.name}}
        </v-card-title>
    </v-card>
</template>

<script>
export default {
    name: "TagCard",
    props: ['tag'],
}
</script>

<style scoped>

</style>
