<template>
    <user-dashboard-layout :loading="loading">
        <v-container>
            <v-row>
                <v-col  sm="12" md="6" xl="3"  v-for="tag in tags" :key="tag.id">
                    <tag-card :tag="tag" />
                </v-col>
            </v-row>
        </v-container>
    </user-dashboard-layout>
</template>

<script>
import UserDashboardLayout from "../../../layouts/UserDashboardLayout";
import TagCard from "@/components/cards/TagCard";
import Tag from "@/models/Tag";

export default {
    name: "Index",
    components: {TagCard, UserDashboardLayout},
    data() {
        return {
            tags: [],
            loading: false,
        }
    },
    async mounted() {
        this.loading = true
        this.tags = await Tag.get()
        this.loading = false
    }
}
</script>

<style scoped>

</style>
